import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

function SEO({ description, lang, meta, keywords, title, ogImage }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s | ${data.site.siteMetadata.title}`}
            meta={[
              {
                name: 'description',
                content: metaDescription,
              },
              {
                property: 'og:title',
                content: title,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                property: 'og:url',
                content: 'https://minimalanalytics.com',
              },
              {
                name: 'og:image',
                content: `https://minimalanalytics.com${ogImage}`,
              },
              {
                name: 'twitter:image:src',
                content: `https://minimalanalytics.com${ogImage}`,
              },
              {
                name: 'twitter:card',
                content: 'summary_large_image',
              },
              {
                name: 'twitter:url',
                content: 'https://minimalanalytics.com',
              },
              {
                name: 'twitter:creator',
                content: data.site.siteMetadata.author,
              },
              {
                name: 'twitter:site',
                content: data.site.siteMetadata.author,
              },
              {
                name: 'twitter:title',
                content: title,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
            ]
              .concat(
                keywords.length > 0
                  ? {
                      name: 'keywords',
                      content: keywords.join(', '),
                    }
                  : []
              )
              .concat(meta)}
          >
          <link href="https://fonts.googleapis.com/css?family=PT+Sans:400,700" rel="stylesheet" />
          <script>
            {`
              (function(d, h, m){
                  var js, fjs = d.getElementsByTagName(h)[0];
                  if (d.getElementById(m)){return;}
                  js = d.createElement(h); js.id = m;
                  js.onload = function(){
                      window.makerWidgetComInit({
                      position: "left",          
                      widget: "keleifrtpuffvmg7-hs2ofugwxhurnzx3-ueivflegujfzpoyz"                
                  })};
                  js.src = "https://makerwidget.com/js/embed.js";
                  fjs.parentNode.insertBefore(js, fjs)
              }(document, "script", "dhm"))
            `}
          </script>
          <script>
            {`
            (function(a,b,c){var d=a.history,e=document,f=navigator||{},g=localStorage,
            h=encodeURIComponent,i=d.pushState,k=function(){return Math.random().toString(36)},
            l=function(){return g.cid||(g.cid=k()),g.cid},m=function(r){var s=[];for(var t in r)
            r.hasOwnProperty(t)&&void 0!==r[t]&&s.push(h(t)+"="+h(r[t]));return s.join("&")},
            n=function(r,s,t,u,v,w,x){var z="https://www.google-analytics.com/collect",
            A=m({v:"1",ds:"web",aip:c.anonymizeIp?1:void 0,tid:b,cid:l(),t:r||"pageview",
            sd:c.colorDepth&&screen.colorDepth?screen.colorDepth+"-bits":void 0,dr:e.referrer||
            void 0,dt:e.title,dl:e.location.origin+e.location.pathname+e.location.search,ul:c.language?
            (f.language||"").toLowerCase():void 0,de:c.characterSet?e.characterSet:void 0,
            sr:c.screenSize?(a.screen||{}).width+"x"+(a.screen||{}).height:void 0,vp:c.screenSize&&
            a.visualViewport?(a.visualViewport||{}).width+"x"+(a.visualViewport||{}).height:void 0,
            ec:s||void 0,ea:t||void 0,el:u||void 0,ev:v||void 0,exd:w||void 0,exf:"undefined"!=typeof x&&
            !1==!!x?0:void 0});if(f.sendBeacon)f.sendBeacon(z,A);else{var y=new XMLHttpRequest;
            y.open("POST",z,!0),y.send(A)}};d.pushState=function(r){return"function"==typeof d.onpushstate&&
            d.onpushstate({state:r}),setTimeout(n,c.delay||10),i.apply(d,arguments)},n(),
            a.ma={trackEvent:function o(r,s,t,u){return n("event",r,s,t,u)},
            trackException:function q(r,s){return n("exception",null,null,null,null,r,s)}}})
            (window,"UA-120315485-2",{anonymizeIp:true,colorDepth:true,characterSet:true,screenSize:true,language:true});
            `
             }
          </script>
          </Helmet>
        )
      }}
    />
  )
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`
