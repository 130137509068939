import React from 'react'
import { Link } from 'gatsby'
import './index.css';

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'
import ogImage from '../images/minimal-analytics.jpg'

import Highlight, { defaultProps } from "prism-react-renderer";
import darkTheme from "prism-react-renderer/themes/oceanicNext";

const exampleCode = `<script>
  (function(a,b,c){var d=a.history,e=document,f=navigator||{},g=localStorage,
  h=encodeURIComponent,i=d.pushState,k=function(){return Math.random().toString(36)},
  l=function(){return g.cid||(g.cid=k()),g.cid},m=function(r){var s=[];for(var t in r)
  r.hasOwnProperty(t)&&void 0!==r[t]&&s.push(h(t)+"="+h(r[t]));return s.join("&")},
  n=function(r,s,t,u,v,w,x){var z="https://www.google-analytics.com/collect",
  A=m({v:"1",ds:"web",aip:c.anonymizeIp?1:void 0,tid:b,cid:l(),t:r||"pageview",
  sd:c.colorDepth&&screen.colorDepth?screen.colorDepth+"-bits":void 0,dr:e.referrer||
  void 0,dt:e.title,dl:e.location.origin+e.location.pathname+e.location.search,ul:c.language?
  (f.language||"").toLowerCase():void 0,de:c.characterSet?e.characterSet:void 0,
  sr:c.screenSize?(a.screen||{}).width+"x"+(a.screen||{}).height:void 0,vp:c.screenSize&&
  a.visualViewport?(a.visualViewport||{}).width+"x"+(a.visualViewport||{}).height:void 0,
  ec:s||void 0,ea:t||void 0,el:u||void 0,ev:v||void 0,exd:w||void 0,exf:"undefined"!=typeof x&&
  !1==!!x?0:void 0});if(f.sendBeacon)f.sendBeacon(z,A);else{var y=new XMLHttpRequest;
  y.open("POST",z,!0),y.send(A)}};d.pushState=function(r){return"function"==typeof d.onpushstate&&
  d.onpushstate({state:r}),setTimeout(n,c.delay||10),i.apply(d,arguments)},n(),
  a.ma={trackEvent:function o(r,s,t,u){return n("event",r,s,t,u)},
  trackException:function q(r,s){return n("exception",null,null,null,null,r,s)}}})
  (window,"XX-XXXXXXXXX-X",{anonymizeIp:true,colorDepth:true,characterSet:true,screenSize:true,language:true});
</script>`;

const title = "Minimal Google Analytics Snippet"
const subtitle = "A simple snippet for tracking page views on your website without having to add external libraries. Also works for single page applications made with the likes of react and vue.js."

const IndexPage = () => (
  <Layout>
    <SEO 
      title={title}
      metaDescription={subtitle}
      ogImage={ogImage}  />
    <div className="index-container">
      <div className="index-left">
        <div>
          <div className="container-small">
            <h1>{title}</h1>
            <p>{subtitle}</p>
            <h3 style={{marginBottom: 5, fontSize: 20}}>Before</h3>
            <p style={{fontSize: 16}}>Google Tag Manager + Analytics = <strong style={{fontSize: 22, color: '#ff5252'}}>73kB</strong></p>
            <h3 style={{marginBottom: 5, fontSize: 20}}>After</h3>
            <p style={{fontSize: 16}}>Snippet = <strong style={{fontSize: 22, color: '#81c784'}}>1.5kB</strong></p>
            <div style={{color: '#888', fontSize: 12.5, marginTop: 40}}>
              <h5 style={{marginBottom: 5, fontWeight: 600, fontSize: 12.5}}>How does this work?</h5>
              <span style={{lineHeight: 1}}>This snippet sends page views directly to the Google Analytics API. There is no need to add the Google Tag Manager and Analytics libraries anymore. That not only saves bandwidth, it can also have a huge positive impact on the loading speed of your website. You should not use this if you want to use advanced features like tracking AdWords.</span>
            </div>
          </div>
        </div>
      </div>
      <div className="index-right">
        <div className="container-small">
          <Highlight {...defaultProps} code={exampleCode} theme={darkTheme} language="js">
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre className={className} style={style}>
                {tokens.map((line, i) => (
                  <div {...getLineProps({ line, key: i })}>
                    {line.map((token, key) => (
                      <span {...getTokenProps({ token, key })} />
                    ))}
                  </div>
                ))}
              </pre>
            )}
          </Highlight>
          <p style={{color: '#767676', fontSize: 13, marginBottom: 10}}><strong>Setup:</strong> Just put the snippet into your html, replace 'XX-XXXXXXXXX-X' with your tracking id and you're ready to go. You can also add options for what information you want to track.</p>
          <p style={{color: '#767676', fontSize: 13, marginBottom: 10}}><strong>Event:</strong> <code>ma.trackEvent('Category', 'Action', 'Label', 'Value')</code></p>
          <p style={{color: '#767676', fontSize: 13, marginBottom: 10}}><strong>Exception:</strong> <code>ma.trackException('Description', 'Fatal')</code></p>
          <div>
            <small><a target="_blank" style={{color: 'inherit'}} href="https://gist.github.com/DavidKuennen/443121e692175d6fc145e1efb0284ec9">View on GitHub</a></small>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
